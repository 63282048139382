import React, {useState} from 'react';
import TextInput from '../../components/app/TextInput';
import Button from '../../components/Button';
import {post} from '../../helpers/api';
import {ERROR_LOGIN, IZIFILL_EMAIL_KEY, IZIFILL_NAME_KEY} from '../../helpers/constants';
import {ORDER_API_URL, LOGIN_URL} from '../../helpers/endpoints';
import {setItemToStorage} from '../../helpers/localStorage';
import {navigateToFill} from '../../helpers/navigation';

const Login = (props) => {
  const {state = {}} = props.location;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onLoginClicked = () => {
    if (email === '' || password === '') {
      setErrorMessage(ERROR_LOGIN.EMPTY_FIELD);
    } else {
      setErrorMessage('');
      loginUser();
    }
  };

  async function loginUser() {
    const url = LOGIN_URL;

    const response = await post(url, {email: email, password: password});
    const data = await response.json();
    if (typeof(data.name) !== 'undefined') {
      setItemToStorage(IZIFILL_NAME_KEY, data.name);
      setItemToStorage(IZIFILL_EMAIL_KEY, data.email);
      postOrderRequest();
      navigateToFill(state);
    } else {
      setErrorMessage(data.message);
    }
  }

  const postOrderRequest = () => {
    post(ORDER_API_URL, {
      volumeId: state.waterOption.id,
      temp: state.temp.name,
      waterStationId: state.stationCode,
      userId: email,
    });
  };

  return (
    <div className="d-flex flex-column login mt-10 mx-2">
      <h2>Login</h2>
      <TextInput required={true} className="mt-2" title="Email" id="Email" type="email" onChangeInput={(e) => setEmail(e.target.value)} />
      <TextInput required={true} className="mt-2" title="Password" id="Password" type="password" onChangeInput={(e) => setPassword(e.target.value)} />
      <Button onClick={onLoginClicked} className="mt-4 px-7 align-self-center" text="Login" buttonClassName="button-secondary" />

      <small className={`mt-2 align-self-center text-danger ${errorMessage === '' ? 'd-none' : 'd-block'}`}>{errorMessage}</small>
    </div>
  );
};

export default Login;
