import React from 'react';

const TextInput = (props) => {
  const {id, pattern, required, title,
    placeholder, onChangeInput, type, className} = props;

  return (
    <div className={className}>
      <small className="my-0 text-muted">{title}</small>
      <input pattern={pattern}
        required={required} aria-required={required}
        className="my-0 w-100 border-0 mt-0" id={id}
        placeholder={placeholder} type={type}
        onChange={onChangeInput}/>

      <hr className="my-0" />
    </div>
  );
};
export default TextInput;
